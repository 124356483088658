<template>
  <div></div>
</template>

<script>
export default {
  name: "code",
  title: "解析车位码",
  created() {
    const qrNum = this.$route.query.qrNum;
    if (qrNum) {
      this.$http.get(
        "/park-service-park/qrCode/selectSeatNumByQrNum",
        { qrNum },
        (res) => {
          if (res.seatNum) {
            this.$router.replace(`/order?seatNum=${res.seatNum}`);
          } else {
            this.$toast.fail("未获取到有效信息，请重新扫描");
          }
        },
        false
      );
    } else {
      this.$toast.fail("未获取到有效信息，请重新扫描");
    }
  },
};
</script>

<style>
</style>